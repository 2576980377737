// eslint-disable-next-line import/prefer-default-export
export function validProgress(progress) {
    if (!progress || progress < 0) {
        return 0;
    }
    if (progress > 100) {
        return 100;
    }
    return progress;
}
